import React, {useState} from 'react';
import OptimizeExample from './OptimizeExample';
import PopularExample from './PopularExample';
import Drawer from '../../components/UiComponent/drawer';
import WrapperStyle from './examples.style';
import ScriptTag from 'react-script-tag';
import PlatformExample from './PlarformExample';

export default () => {
    const [visible, setVisible] = useState(false);
    const [promotionId, setPromotionId] = useState(null);
    const [drawerTitle, setDrawerTitle] = useState(null);
    const showDrawer = (id, title) => {
        setVisible(true);
        setPromotionId(id);
        setDrawerTitle(title)
    };
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h1>Instagramのキャンペーン事例・サンプル
                    </h1>
                    <p>
                        Instagramのいいね・コメント・フォロー・リポストなどの応募条件とするキャンペーンを作成できます。ギャラリー機能を使用すればUGCコンテンツやフォトコンテストも開催できます。
                    </p>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <a data-retach-widget="embed" href="https://retach.biz/promotions/34525175aa43570ea1" rel="nofollow">リタッチ</a>
                        <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
                    </div>
                </div>
            </div>
        </section>
          <PlatformExample title="Instagramのキャンペーンサンプル" types={['instagramFollowLike', 'instagramFollowRepost', 'instagramFollowHashtag', 'password', 'instagramShare', 'instagramPhotoContest']} />
          <PopularExample exclude={['password','instagramShare','instagramPhotoContest']} excludeProviders={['instagram']} showDrawer={showDrawer}/>
          <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};