import React from 'react';
import Layout from '../../../components/App/Layout';
import Navbar from '../../../components/App/Navbar';
import ContentBanner from '../../../components/Common/ContentBanner';
import Footer from '../../../components/App/Footer';
import InstagramCampaign from '../../../components/Examples/InstagramCampaign';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/instagram/instagram.jpg';

const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Instagramのキャンペーン事例・サンプル"
		  desc="リタッチのInstagramキャンペーンの事例・サンプルです。いいね・フォロー・リポスト・写真コンテスト・コメントの応募条件を指定できます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/instagram'}
		  type={'Article'}
		  shortName="Instagramのキャンペーン事例・サンプル"
		  createdAt="2021-07-26"
		/>
	  <Navbar />
	  <ContentBanner
		homePageText="Home"
		homePageUrl="/"
		activePageText="Instagramキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />

	  <InstagramCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples